<template>
  <travio-center-container grid-width='full'>
    <h2 style="color:#636363">Location Lists</h2>
    <p v-html="infoLink" class="mt-2 mb-4"></p>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full mt-4">
          <vs-button @click="addLocationList" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="locationLists"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        rowSelection="single"
        :pagination="true"
        :context="context"
        :suppressPaginationPanel="false"
      >
      </ag-grid-vue>
      
    </vx-card>
    <location-list-name-editor 
      v-if="showNameEditor"
      :applicationId="applicationId"
      @onAddSuccess="onAddListSuccess"
      @onCancel="showNameEditor=false" 
    />
  
  </travio-center-container>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererLocationListActions from './cell-renderers/CellRendererLocationListActions.vue'
import LocationListNameEditor from './components/LocationListNameEditor.vue'

export default {
  components: {
    AgGridVue,
    CellRendererLocationListActions,
    LocationListNameEditor
  },
  props: {
    applicationId: { required: true },
  },
  data () {
    return {
      locationLists: [],
      searchRequest: null,
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererLocationListActions
      },
      showNameEditor: false,
      context: null,
      infoLink: 'Create and manage reusable custom location lists for use in your searchbox widgets. To add a location list to your searchbox widgets, you will need to make a note of the List ID.'
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    this.$vs.loading()

    try {
      const response = await this.$http.get(`api/locationLists/apps/${this.applicationId}`)
      this.locationLists = response.data

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererLocationListActions) },
      { headerName: 'List ID', field: 'id', width: 120,  suppressSizeToFit: true },
      { headerName: 'Location List Name', field: 'name', width: 300,  suppressSizeToFit: false },
      { 
        headerName: 'Created', 
        field: 'createdDate',
        width: 250,
        suppressSizeToFit: true,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateFormat)
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    addLocationList () {
      this.showNameEditor = true
    },
    editLocationList(listId) {
      this.$router.push({
        name: 'application-tools-location-editor-level1',
        params: { 
          applicationId: this.$route.params.applicationId,
          locationListId: listId
        }
      })
    },
    onAddListSuccess (newLocationList) {
      this.locationLists = [...this.locationLists, newLocationList]
      this.showNameEditor = false
    },
    onEditListSuccess (newName) {
      const udpatedItem = this.locationLists.find(x => x.id == newLocationList)
      udpatedItem.name = newName
      this.locationLists = [...this.locationLists]
      this.showNameEditor = false
    },
    async deleteLocationList(locationListId) {
      this.$vs.loading()
      try {
        await this.$http.delete(`api/locationLists/apps/${this.applicationId}/${locationListId}`)
        this.$_notifySuccess('Successfully deleted location list');
        this.locationLists = [...this.locationLists.filter(x => x.id != locationListId)]
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    }
  }
}
</script>

<style>

</style>
